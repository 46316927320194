<template>
    <div class="component-container white">
        <div class="venture-list">
            <VentureOverview v-for="venture in ventures" :key="venture.id" :venture="venture"/>
        </div>
    </div>
</template>

<script>
import VentureOverview from './Elements/VentureOverview.vue';

export default {
    name: 'VentureList',

    props: [
        'ventures'
    ],

    components: {
        VentureOverview
    },

    computed: {

    }
}
</script>

<style lang="scss" scoped>
.component-container {

    .venture-list {
        max-width: $container-width;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        @include breakpoint('mobile') {
            grid-template-columns: 1fr;
        }
    }

    padding-bottom: 64px;

}
</style>
