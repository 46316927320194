<template>
    <div>
        <div class="entry-short" v-bind:style="{ backgroundImage: 'url(' + cmsPageHeaderImage + ')' }">
            <div v-if="cmsPage" class="context">
                <h1 v-if="cmsPage.data.attributes.title">{{ localize(cmsPage.data.attributes.title) }}</h1>
                <h2 v-if="cmsPage.data.attributes.subtitle">{{ localize(cmsPage.data.attributes.subtitle) }}</h2>
            </div>
        </div>

        <PageBuilder v-if="cmsPage" :cmsBlocks="cmsPage.data.attributes.cmsBlocks" />

        <div class="component-container white">
            <VentureList v-if="ventures" :ventures="ventures.data" />
        </div>
    </div>
</template>

<script>
import PageBuilder from '@/components/PageBuilder'
import VentureList from '@/components/PageBuilder/VentureList'
import {
    venturesCmsPageQuery,
    venturesQuery,
    globalOptionQuery
} from '@/queries'

export default {
    name: 'Ventures',

    components: {
        PageBuilder,
        VentureList
    },

    data() {
        return {
            cmsPage: null,
            ventures: null,
        }
    },

    apollo: {
        cmsPage: {
            query: venturesCmsPageQuery,
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            update: data => data.ventureList
        },

        ventures: {
            query: venturesQuery,
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            update: data => data.ventureProfiles
        },

        defaultCmsPageHeader: {
            query: globalOptionQuery,
            variables: {
                optionId: 'default-cms-page-header'
            },
            fetchPolicy: 'cache-first',
            update: data => data.globalOptions.data[0]
        }
    },

    computed: {
        cmsPageHeaderImage() {
            return this.defaultCmsPageHeader ? this.getImageUrl(this.localize(this.defaultCmsPageHeader.attributes.image).data.attributes.url) : ''
        },
    },

    metaInfo() {
        var meta = []

        meta.push({
            vmid: 'og:title',
            name: 'og:title',
            content: "REACH - Unsere Start-ups"
        })

        meta.push({
            vmid: 'description',
            name: 'description',
            content: "Lerne alle Start-ups kennen, die wir mit unserem Inkubator-Programm unterstützt haben und erfahre mehr über ihre Gründungsgeschichte."
        })
        meta.push({
            vmid: 'og:description',
            name: 'og:description',
            content: "Lerne alle Start-ups kennen, die wir mit unserem Inkubator-Programm unterstützt haben und erfahre mehr über ihre Gründungsgeschichte."
        })

        return {
            title: this.metaTitle,
            meta: meta
        }
    },

    methods: {
        url(venture) {
            return '/start-ups/' + venture.attributes.slug
        }
    }
}
</script>

<style lang="scss" scoped>
.component-container {
    max-width: $container-width;
    margin: 0 auto;

    .left {
        padding: getSpacing('padding-small-vertical', 'desktop') getSpacing('padding-default-horizontal', 'desktop');
        text-align: left;

        @include breakpoint('tablet') {
            padding: getSpacing('padding-small-vertical', 'tablet') getSpacing('padding-default-horizontal', 'tablet');
        }

        @include breakpoint('mobile') {
            padding: getSpacing('padding-small-vertical', 'mobile') getSpacing('padding-default-horizontal', 'mobile');
        }
    }
}
</style>
