<template>
    <div class="venture-card">
        <div class="inner-wrap">
            <div class="venture-logo">
                <img :src="getImageUrl(venture.attributes.logo.data.attributes.url)" alt="venture logo">
            </div>
            <h3 class="venture-name">{{ venture.attributes.Name }}</h3>
            <div class="venture-description" v-html="md(localize(venture.attributes.shortDescription))" />
            <router-link class="venture-button" :to="`/start-ups/${venture.attributes.slug}`">
                <button class="button-dark">
                    {{ $t('ventureListProfileButton') }}
                </button>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VentureOverview',

    props: [
        'venture',
    ],

    components: {
    },

    computed: {

    },
}
</script>

<style lang="scss" scoped>
.venture-card {
    position: relative;
    background: $lightGrey;
    padding-bottom: 100px;
    margin: getSpacing('padding-xsmall-vertical', 'desktop') getSpacing('padding-xsmall-horizontal', 'desktop');

    @include breakpoint('tablet') {
        margin: getSpacing('padding-xsmall-vertical', 'tablet') getSpacing('padding-xsmall-horizontal', 'tablet');
    }

    @include breakpoint('mobile') {
        margin: getSpacing('padding-xsmall-vertical', 'mobile') getSpacing('padding-default-horizontal', 'mobile');
    }

    .inner-wrap {
        display: flex;
        flex-direction: column;
        min-height: 150px;
        align-items: center;

        .venture-logo {
            padding: getSpacing('padding-small-horizontal', 'desktop') getSpacing('padding-small-horizontal', 'desktop');
            text-align: center;
            height: 150px;

            img {
                height: 100%;
                width: 66%;
                object-fit: contain;
            }
        }

        .venture-description {
            padding: 0px getSpacing('padding-small-horizontal', 'desktop');
            text-align: center;

            ::v-deep p {
                margin: 0 0;
                font-size: getFontSize('smallText', 'desktop');

                @include breakpoint('tablet') {
                    font-size: getFontSize('smallText', 'tablet');
                }

                @include breakpoint('mobile') {
                    font-size: getFontSize('smallText', 'mobile');
                }
            }
        }

        .venture-button {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            padding-top: getSpacing('padding-small-horizontal', 'desktop');
            padding-bottom: getSpacing('padding-small-horizontal', 'desktop');
        }
    }
}
</style>
